import React from 'react'
import PropTypes from 'prop-types'
import PretparkLogo from '../PretparkLogo/PretparkLogo'
import Featured from '../Featured/Featured'
import Aside from '../Aside/Aside'
import BigSquare from '../BigSquare/BigSquare'
import LeaderBoard from '../LeaderBoard/LeaderBoard'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export default class BlogPostTemplate extends React.Component {
  render() {
    const {
      bigSquareAside,
      title,
      categories,
      tags,
      featuredImage,
      content,
      meer,
      banners,
      weatherData,
    } = this.props
    const pos = meer.map ? [meer.map.latitude, meer.map.longitude] : null
    const { pretparkLogo, bijzonderheden } = meer
    const { bigSquareSrc, leaderboardSrc } = banners
    const { weatherPlaats, weatherId } = weatherData

    return (
      <section className="section-post">
        <div className="container content">
          <div className="columns">
            <div className="column is-9">
              <nav>
                <Link to="/">
                  {' '}
                  <FontAwesomeIcon
                    style={{
                      'padding-right': '12px',
                    }}
                    icon={faArrowLeft}
                  />
                  Terug naar overzicht
                </Link>
              </nav>
              <h1 className="title">{title}</h1>

              {pretparkLogo ? <PretparkLogo src={pretparkLogo} /> : null}

              {leaderboardSrc ? <LeaderBoard src={leaderboardSrc} /> : null}

              <div dangerouslySetInnerHTML={{ __html: content }} />

              {bijzonderheden ? (
                <div className="bijzonderheden img-marg is-clearfix">
                  <span>
                    <strong>Attracties: </strong>
                  </span>
                  <span dangerouslySetInnerHTML={{ __html: bijzonderheden }} />
                </div>
              ) : null}

              {!bigSquareAside ? <BigSquare src={bigSquareSrc} /> : null}

              {featuredImage ? <Featured src={featuredImage} /> : null}
            </div>
            <div className="column is-3">
              {bigSquareAside ? (
                <Aside
                  showBigSquare={bigSquareAside}
                  bigSquareSrc={bigSquareSrc}
                  pos={pos}
                  categories={categories}
                  tags={tags}
                  bijzonderheden={bijzonderheden}
                  title={title}
                  weatherPlaats={weatherPlaats}
                  weatherId={weatherId}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string,
  bigSquareAside: PropTypes.bool,
  pretparkLogo: PropTypes.string,
  featuredMedia: PropTypes.string,
  bijzonderheden: PropTypes.string,
  bigSquareSrc: PropTypes.string,
  leaderboardSrc: PropTypes.string,
  weatherPlaats: PropTypes.string,
  weatherId: PropTypes.string,
}
