import React from 'react'
import PropTypes from 'prop-types'
import Plan from '../Plan/Plan'
import BigSquare from '../BigSquare/BigSquare'
import Weather from '../Weather/Weather'

export default class Aside extends React.Component {
  render() {
    const {
      pos,
      showBigSquare,
      bigSquareSrc,
      title,
      weatherPlaats,
      weatherId,
    } = this.props

    return (
      <aside>
        {showBigSquare && bigSquareSrc ? (
          <BigSquare src={bigSquareSrc} />
        ) : null}

        {pos ? <Plan pos={pos} /> : null}

        {weatherPlaats && weatherId ? (
          <Weather weatherPlaats={weatherPlaats} weatherId={weatherId} />
        ) : null}
      </aside>
    )
  }
}

Aside.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.array,
      tags: PropTypes.array,
    })
  ),
}
